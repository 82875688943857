














































import { Observer } from 'mobx-vue'
import { Component, Inject, Vue } from 'vue-property-decorator'
import { FollowingViewModel } from '../../../viewmodels/following-viewmodel'

@Observer
@Component({
  components: {
    'dao-item': () => import('./dao-item.vue'),
  },
})
export default class FollowingTable extends Vue {
  @Inject({}) vm!: FollowingViewModel

  follow(item) {
    this.vm.followDao(item)
  }

  unFollow(item) {
    this.vm.unFollowDao(item)
  }
}
